<template>
  <v-card elevation="0" flat>
    <v-app-bar flat dark color="primary">
      <v-toolbar-title>Proposer à un prestataire</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form class="px-3">
        <v-select label="Métiers"
                  :items="metiers"
                  v-model="metier"
                  item-text="name"
                  item-value="id"></v-select>
        <v-select label="Prestataire"
                  :items="prestataires"
                  item-text="fullname"
                  v-model="prestataire"
                  item-value="id"></v-select>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="reassignTicket">
        Assigner le ticket
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat)

export default {
  name: 'PrestatairePropositionForm',
  data() {
    return {
      "metier": null,
      "prestataire": null,
    }
  },
  mounted() {
    if (this.metiers.length === 0) {
      this.$store.dispatch("categories/fetchCategories");
    }
    if (this.users.length === 0) {
      this.$store.dispatch("users/fetchUsers");
    }
  },
  computed: {
    ...mapGetters("categories", [
      "metiers",
    ]),
    ...mapGetters("users", [
      "prestataires",
      "users",
      "collaborateurs",
    ]),
    selected_intervention() {
      return this.$store.getters["interventions/getSelectedIntervention"];
    }
  },
  methods: {
    reassignTicket() {
      let ticketId = this.selected_intervention.ticket.id;
      let intervention = {
        "id": this.selected_intervention.id,
        "validation": false,
        "prestataire": this.prestataire,
        "categorie": this.metier
      }
      let store = this.$store;
      this.$store.dispatch("interventions/patchIntervention", intervention).then(async function () {
        await this.$store.dispatch("tickets/patchTicket", {"id": ticketId,"state": "ready"});
        store.dispatch("messages/setAnnounce", {"type": "success", "message": "Intervention créée !"});
      }).catch(function (err) {
        console.error(err);
      });
      this.$emit("close-prestataire-drawer");
      this.resetFields();
    },
    resetFields() {
      this.metier = null;
      this.prestataire = null;
    },

  }
}
</script>

<template>
  <v-card elevation="0" flat>
    <v-app-bar flat dark color="primary">
      <v-toolbar-title>Assigner à un collaborateur</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form class="px-3">
        <v-autocomplete label="Métiers"
                        :items="metiers"
                        v-model="metier"
                        item-text="name"
                        item-value="id"></v-autocomplete>
        <v-select label="Collaborateur"
                  :items="collaborateurs"
                  item-text="fullname"
                  v-model="prestataire"
                  item-value="id"></v-select>
        <v-row>
          <v-col cols="8">
            <v-menu :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Date du début"
                              :value="getFormattedDate(date_debut)"></v-text-field>
              </template>
              <v-date-picker v-model="date_debut" @change="date_fin = date_debut"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure du début"
                              :value="heure_date_debut"></v-text-field>
              </template>
              <v-time-picker format="24hr" v-model="heure_date_debut"></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-menu :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Date du fin"
                              :value="getFormattedDate(date_fin)"></v-text-field>
              </template>
              <v-date-picker v-model="date_fin"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure de fin"
                              :value="heure_date_fin"></v-text-field>
              </template>
              <v-time-picker format="24hr" v-model="heure_date_fin"></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="assignTicket">
        Assigner le ticket
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat)

export default {
  name: 'CollabAssignmentForm',
  data() {
    return {
      "metier": null,
      "prestataire": null,
      "date_debut": null,
      "heure_date_debut": null,
      "date_fin": null,
      "heure_date_fin": null,
    }
  },
  mounted() {
    if (this.metiers.length === 0) {
      this.$store.dispatch("categories/fetchCategories");
    }
    if (this.users.length  < 2) {
      this.$store.dispatch("users/fetchUsers");
    }
  },
  computed: {
    ...mapGetters("categories", [
      "metiers",
    ]),
    ...mapGetters("users", [
      "prestataires",
      "users",
      "collaborateurs",
    ]),
    selected_intervention() {
      return this.$store.getters["interventions/getSelectedIntervention"];
    }
  },
  methods: {
    getFormattedDatetime(date, time) {
      return date + " " + time;
    },
    getCleanDate(date, time) {
      return date !== null && time !== null ? dayjs(this.getFormattedDatetime(date, time), "YYYY-MM-DD hh:mm").toISOString() : null;
    },
    getFormattedDate(date) {
      return date != null ? dayjs(date).format("DD/MM/YYYY") : null;
    },
    async assignTicket() {
      let intervention = {
        "id": this.selected_intervention.id,
        "date_intervention": this.getCleanDate(this.date_debut, this.heure_date_debut),
        "date_fin_intervention": this.getCleanDate(this.date_fin, this.heure_date_fin),
        "validation": true,
        "prestataire": this.prestataire,
        "categorie": this.metier
      }
      let ticketId = this.selected_intervention.ticket.id;
      await this.$store.dispatch("interventions/patchIntervention", intervention);
      await this.$store.dispatch("tickets/patchTicket", {"id": ticketId,"state": "planned"});
      this.$emit("close-assignment-drawer");
      this.resetFields();
    },
    resetFields() {
      this.metier = null;
      this.prestataire = null;
      this.date_debut = null;
      this.heure_date_debut = null;
      this.date_fin = null;
      this.heure_date_fin = null;
    }
  }
}
</script>

<template>
  <v-data-iterator loading-text="Chargement des interventions en cours..."
                   :loading="isLoadingInterventions"
                   no-data-text="Aucune intervention ne correspond à votre demande." :items="$store.getters['interventions/getFilteredInterventions']" item-key="id" :items-per-page="5">
    <template v-slot:default="{ items }">
      <v-card outlined @click="setIntervention(intervention)" elevation="0" v-for="intervention in items" :key="intervention.id">
        <v-row no-gutters class="pa-3">
          <v-row class="pl-2 mb-2">
            <v-col class="pa-0 ma-0">
              <h3 class="blue--text text--darken-3">Intervention du ticket #{{intervention.ticket.id}} : {{ intervention.ticket.objet }}
              <span v-if="showInterventionNumber.value === 'true'" class="orange--text font-italic">#{{ intervention.id }}</span></h3>
            </v-col>
            <v-col class="pa-0 ma-0">
              <InterventionStatutChip :intervention="intervention"/>
            </v-col>
          </v-row>
          <v-col cols="12" v-if="intervention.prestataire !== null">
            <h4 class="grey--text font-italic">Assigné à : {{ intervention.prestataire.fullname }}</h4>
          </v-col>
          <v-col cols="12" v-else>
            <h4 class="grey--text font-italic">En cours d'assignation</h4>
          </v-col>
          <v-col v-if="intervention.ticket.asset != null" cols="12" class="pa-0 ma-0">
            <span class="grey--text">
              <v-icon>mdi-hammer-wrench </v-icon> {{ intervention.ticket.asset.name }}
            </span><br>
            <span v-if="intervention.ticket.asset.site !== null" class="grey--text">
              <v-icon left>mdi-map-marker</v-icon>
              <span v-if="intervention.ticket.asset !== null">{{intervention.ticket.asset.site.name }}</span>
              <span v-if="intervention.ticket.asset.site.parent != null">, {{intervention.ticket.asset.site.parent }}</span>
              <span v-if="intervention.ticket.asset.site.top != null">, {{intervention.ticket.asset.site.top }}</span>
              <span v-if="intervention.ticket.asset.site == null" class="font-italic grey--text">Non défini</span>
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <v-chip color="primary" v-if="intervention.categorie !== null">
              <span class="white--text">{{ intervention.categorie.name }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </v-card>
    </template>
  </v-data-iterator>
</template>
<script>
import dayjs from "dayjs";
import InterventionMixin from "@/mixins/interventions/InterventionMixin";
import InterventionStatutChip from "@/components/interventions/InterventionStatutChip";

export default {
  name: 'InterventionList',
  components: {InterventionStatutChip},
  mixins: [InterventionMixin],
  props: {
    interventionsLoaded: Boolean,
  },
  created() {
    this.dayjs = dayjs;
  },
  computed: {
    isLoadingInterventions() {
      return this.interventionsLoaded === false && this.$store.getters["interventions/interventions"].length === 0;
    },
    showInterventionNumber() {
      let showInterventionNumber = this.$store.getters["config/showInterventionNumber"]
      return showInterventionNumber !== undefined ? showInterventionNumber : {value: false};
    },
  },
  methods: {
    setIntervention(intervention) {
      this.$store.commit("interventions/setSelectedIntervention", intervention);
      const path = `/interventions/${intervention.id}`;
      if (this.$route.path !== path) this.$router.push(path);
      this.$vuetify.goTo(0);
    },
  },
}
</script>
